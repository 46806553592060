<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <alert />
            <RoomsTable />
          </div>
        </div>
      </div>
    </section>
    <b-modal
      id="modal-route"
      :on-enter-key-press="routeProceed"
      hide-footer
      hide-title
    >
      <div class="d-block text-center">
        <p>{{ $t('changesNotSaved') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-route')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1" primary @click="routeProceed">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  SET_ALERT,
  SET_IS_FORM_DIRTY
} from '@/store/modules/common/mutationTypes'

import RoomsTable from '@/components/common/RoomsTable'
import Alert from '@/components/common/Alert'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  name: 'RoomsPage',
  components: {
    RoomsTable,
    Alert,
    'b-modal': BootstrapModalHOC
  },
  data() {
    return {
      nextPage: ''
    }
  },
  updateAlert(variant, text) {
    this.$store.commit(`common/${SET_ALERT}`, {
      seconds: 3,
      text,
      variant: variant || 'success'
    })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$store.state.dirtyForm) {
      next()
    } else {
      next(false)
      this.nextPage = to.name
      this.$bvModal.show('modal-route')
    }
  },
  methods: {
    routeProceed() {
      this.$store.commit(`common/${SET_IS_FORM_DIRTY}`, false)
      this.$router.push({ name: this.nextPage }, () => {})
    }
  }
}
</script>
<style lang="scss" scoped></style>
