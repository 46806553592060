<template>
  <div>
    <div class="row pb-4  df">
      <h2 class="col m-1">{{ $t('rooms') }}</h2>
      <span
        v-if="isAdmin"
        class="mt-2 mb-2 mr-5 btn btn-sm  btn-secondary d-lg-none"
        @click="showCreateRoom"
        >+ {{ $t('add') }}</span
      >

      <div class="col-md-auto m-1">
        <input
          id="table-filter"
          ref="filter"
          type="text"
          class="form-control"
          :placeholder="$t('filterTable')"
          @keyup="filterTable"
        />
      </div>
    </div>
    <form ref="table" class="table-responsive" @submit.prevent="null">
      <table class="table-style table-settings table_sort">
        <thead>
          <tr>
            <th>{{ $t('room') }}</th>
            <th>{{ $t('floor') }}</th>
            <th>{{ $t('attributes') }}</th>
            <th>
              <span
                v-if="isAdmin"
                class="btn btn-secondary"
                @click="showCreateRoom"
                >+ {{ $t('add') }}</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(room, index) in rooms">
            <tr
              :key="index"
              :data-val="room.name + ' ' + room.floorName"
            >
              <td :data-label="$t('room') + ':'">
                {{ room.name }}
              </td>
              <td :data-label="$t('floor') + ':'">
                {{ room.floorName }}
              </td>
              <td :data-label="$t('attributes') + ':'">
                <template v-if="room.attributes">
                  <div
                    class="d-flex flex-wrap justify-content-center"
                  >
                    <base-pill
                      v-for="(roomAttribute, key) in room.attributes"
                      :key="`room-attribute-${key}`"
                      :item="roomAttribute"
                    />
                  </div>
                </template>
              </td>
              <td data-label class="text-right">
                <span
                  class="link-edit text-primary"
                  @click="showEditRoom(index)"
                >
                  <font-awesome-icon icon="edit" />
                  {{ $t('edit') }}
                </span>
                |
                <span
                  class="link-remove"
                  @click="
                    ;(currentIndex = index),
                      $bvModal.show('modal-remove')
                  "
                >
                  <font-awesome-icon icon="times" />
                  {{ $t('remove') }}
                </span>
                |
                <span
                  class="link-edit"
                  @click="showAttributesModal(room)"
                >
                  <font-awesome-icon icon="list" />
                  {{ $t('attributes') }}
                </span>
              </td>
            </tr>
          </template>
          <div v-if="!rooms.length">
            <tr>
              <td>
                <strong>{{ $t('noRecordsFound') }}</strong>
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </form>
    <b-modal
      id="modal-remove"
      :on-enter-key-press="() => removeRoom(currentIndex)"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-remove')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          class="mt-1 ml-1"
          primary
          @click="removeRoom(currentIndex)"
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
    <room-modal
      :room="selectedRoom"
      :floors="floors"
      :show="showUpsertRoom"
      :mode="mode"
      :google-integration="googleIntegration"
      :azure-integration="azureIntegration"
      @input="room => (selectedRoom = room)"
      @cancel="cancelUpsert"
      @done="showUpsertRoom = false"
    />

    <attributes-modal
      v-if="selectedValue"
      :attributes="localAttributes"
      :modal-title="`Attributes - ${selectedValue.name}`"
      :selected-value="selectedValue"
      @update-selected-attributes="updateSelectedValueAttributes"
      @refresh-data="refreshRooms"
      @updateAlert="showAlert"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'

import { filterTableMixin } from '@/mixins/mixins'
import AttributesMixin from '@/mixins/AttributesMixin'
import OkkuApi from '@/services/OkkuApi'
import { uuidv4 } from '@/services/Helper'
import {
  SET_ALERT,
  SET_GOOGLE_ROOMS,
  SET_AZURE_ROOMS,
  SET_ROOMS
} from '@/store/modules/common/mutationTypes'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import RoomModal from '@/components/modals/RoomModal'

export default {
  name: 'RoomsTable',
  components: {
    RoomModal,
    'b-modal': BootstrapModalHOC,
    AttributesModal: () =>
      import('@/components/modals/AttributesModal.vue'),
    BasePill: () => import('@/components/common/BasePill.vue')
  },
  mixins: [filterTableMixin, AttributesMixin],
  data: () => ({
    currentIndex: null,
    selectedRoom: {
      settings: {}
    },
    rooms: [],
    organisations: [],
    organisationDetails: null,
    mode: null,
    showUpsertRoom: false,
    integration: false,
    googleIntegration: false,
    azureIntegration: false
  }),
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      storeRooms: 'rooms',
      isDashboardAdmin: '$isDashboardAdmin',
      floors: 'floors',
      allOrganisations: '$userInfo'
    }),
    ...mapGetters('common', ['isAdmin'])
  },
  watch: {
    storeRooms() {
      this.rooms = _cloneDeep(this.storeRooms)
    }
  },
  async created() {
    await this.getAllAttributes()
    await this.refreshRooms()
  },
  async mounted() {
    this.rooms = _cloneDeep(this.storeRooms)
    this.organisations = _cloneDeep(this.allOrganisations)
    await this.checkIntegration()

    if (this.googleIntegration) {
      await this.getGoogleRooms()
    }
    if (this.azureIntegration) {
      await this.getAzureRooms()
    }
  },
  methods: {
    async refreshRooms() {
      await OkkuApi.getRooms().then(rooms => {
        this.$store.commit(`common/${SET_ROOMS}`, rooms)
        this.refreshSelectedValue(rooms)
      })
    },
    showAlert(payload) {
      this.updateAlert(payload.variant, payload.text)
    },
    updateAlert(variant, text) {
      this.$store.commit(`common/${SET_ALERT}`, {
        seconds: 3,
        text,
        variant: variant || 'success'
      })
    },
    updateFloorName(value) {
      return this.floors.find(floor => floor.id === value)?.name
    },
    cancelUpsert() {
      this.showUpsertRoom = false
    },
    removeRoom(index) {
      OkkuApi.deleteRoom(
        this.rooms[index].id,
        this.rooms[index].roomEmail
      )
        .then(response => {
          this.rooms.splice(index, 1)
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: this.$t('deleteSuccess'),
            variant: 'success'
          })
          this.$store.commit(`common/${SET_ROOMS}`, this.rooms)
          if (this.googleIntegration) this.getGoogleRooms()
          if (this.azureIntegration) this.getAzureRooms()
        })
        .catch(error => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: error.message,
            variant: 'danger'
          })
        })
      this.$bvModal.hide('modal-remove')
    },
    async showCreateRoom() {
      this.mode = 'create'
      this.selectedRoom = {
        lastoccupied: null,
        parentId: '',
        svgSnippet: '',
        name: '',
        id: uuidv4(),
        regularUser: null,
        settings: {
          m2: null,
          maxOccupants: null,
          privacySettings: 'show all details'
        },
        floorName: '',
        floorId: '',
        resourceEmail: '',
        emailAddress: ''
      }
      if (this.googleIntegration && this.azureIntegration) {
        await this.getGoogleRooms()
        await this.getAzureRooms()
      } else if (this.googleIntegration) {
        await this.getGoogleRooms()
      } else if (this.azureIntegration) {
        await this.getAzureRooms()
      } else {
        const arr = []
        await this.$store.commit(`common/${SET_GOOGLE_ROOMS}`, arr)
        await this.$store.commit(`common/${SET_AZURE_ROOMS}`, arr)
      }
      this.showUpsertRoom = true
    },
    async showEditRoom(index) {
      this.mode = 'edit'
      this.selectedRoom = _cloneDeep(this.rooms[index])

      if (this.selectedRoom.bindingType === 'azure') {
        this.selectedRoom.emailAddress = this.selectedRoom.roomEmail
        delete this.selectedRoom.roomEmail
      } else if (this.selectedRoom.bindingType === 'google') {
        this.selectedRoom.resourceEmail = this.selectedRoom.roomEmail
        delete this.selectedRoom.roomEmail
      }
      if (this.googleIntegration && this.azureIntegration) {
        await this.getGoogleRooms(this.rooms[index].bindId)
        await this.getAzureRooms(this.rooms[index].bindId)
      } else if (this.googleIntegration) {
        await this.getGoogleRooms(this.rooms[index].bindId)
      } else if (this.azureIntegration) {
        await this.getAzureRooms(this.rooms[index].bindId)
      } else {
        const arr = []
        await this.$store.commit(`common/${SET_GOOGLE_ROOMS}`, arr)
        await this.$store.commit(`common/${SET_AZURE_ROOMS}`, arr)
      }
      this.showUpsertRoom = true
    },
    getGoogleRooms(id = null) {
      OkkuApi.getGoogleRooms(id)
        .then(rooms => {
          this.$store.commit(`common/${SET_GOOGLE_ROOMS}`, rooms)
        })
        .catch(error => {
          this.updateAlert('danger', error.message)
        })
    },
    async getAzureRooms(id = null) {
      try {
        const azureRooms = await OkkuApi.getAzureRooms(id)
        azureRooms
          ? this.$store.commit(
              `common/${SET_AZURE_ROOMS}`,
              azureRooms
            )
          : this.$store.commit(`common/${SET_AZURE_ROOMS}`, [])
      } catch (error) {
        if (error.response?.status === 401) {
          const alertText = error.response.data.split('https')
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 10,
            text: alertText[0],
            variant: 'danger',
            consentLink: `https${alertText[1]}https://dashboard.okku.io`
          })
        } else {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 5,
            text: error.response.data,
            variant: 'danger'
          })
        }
      }
    },
    checkIntegration() {
      this.organisationDetails = this.$store.getters[
        'common/getOrganisation'
      ]
      const { integrations } = this.organisations.groups[
        this.organisationDetails
      ]
      const { googleIntegration } = integrations || undefined
      const { azureIntegration } = integrations || undefined

      if (googleIntegration && googleIntegration.enabled) {
        this.googleIntegration = true
      }
      if (azureIntegration && azureIntegration.enabled) {
        this.azureIntegration = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}
table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
